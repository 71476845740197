import {QueryResult} from "@vendure/admin-ui/core";
import {lastValueFrom, Observable, take} from "rxjs";

export const queryResultToPromise = <T, V extends Record<string, any>>(queryResult: QueryResult<T, V>): Promise<T> => {
  return observableToPromise(queryResult.single$);
};

export const observableToPromise = <T>(observable: Observable<T>): Promise<T> => {
  const source$ = observable.pipe(take(1));

  return lastValueFrom(source$);
};
